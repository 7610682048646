
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import { UserAuthUpdate } from "@/models/user";
import { defineComponent } from "vue";
import authStore from "@/store/auth";
import authHttp from "@/http/auth";
import { helperMixin } from "@/mixins";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import BaseFileInput from "@/components/Base/BaseFileInput.vue";

export default defineComponent({
  mixins: [helperMixin],
  components: {
    BaseFormApp,
    BaseFormGroup,
    CompanyLogo,
    TeleportToContentFooter,
    BaseFileInput
  },
  data() {
    return {
      validationErrors: {} as any,
      form: {
        email: authStore().state.user.email,
        first_name: authStore().state.user.first_name,
        last_name: authStore().state.user.last_name,
      } as UserAuthUpdate
    };
  },
  computed: {
    authStore,
    authHttp
  },
  methods: {
    async submit() {
      this.validationErrors = {};

      const { response } = (await this.requestHandler(
        this.authHttp.updateUser(this.form)
      )) ?? { response: null };

      if (response) {
        this.authStore.state.user = response.data;
      }

      this.validationErrors = this.authHttp.validationErrors;
    }
  }
});
